<template>
  <div>
    <v-row>
      <v-col cols="12" class="d-flex">
        <v-spacer></v-spacer>
        <v-select :items="shopList" v-model="shopSelection" no-data-text="ไม่มีข้อมูล" label="เลือกร้านค้า" filled dense
                  item-text="shop_name"
                  item-value="shop_id"
                  @change="getDashboard"
                  hide-details
                  style="max-width: 300px"/>
      </v-col>
      <v-col cols="12" md="6" lg="4" class="align-self-end">
        <SaleDaily
            :avatar="saleDaily.avatar"
            :avatar-width="saleDaily.avatarWidth"
            :statistics="saleDaily.statistics"
            :stat-title="saleDaily.statTitle"
            :loading="loadingLine"
        ></SaleDaily>
      </v-col>
      <v-col cols="12" md="6" lg="4" class="align-self-end">
        <MonthDaily
            :avatar="saleMonthly.avatar"
            :avatar-width="saleMonthly.avatarWidth"
            :statistics="saleMonthly.statistics"
            :stat-title="saleMonthly.statTitle"
            :loading="loadingLine"
        ></MonthDaily>
      </v-col>
      <v-col cols="12" md="6" lg="4" class="align-self-end">
        <YearDaily
            :avatar="saleYearly.avatar"
            :avatar-width="saleYearly.avatarWidth"
            :statistics="saleYearly.statistics"
            :stat-title="saleYearly.statTitle"
            :loading="loadingLine"
        ></YearDaily>
      </v-col>
      <v-col cols="12" md="6">
        <SaleCompare :chart-data="chartDataLine" :loading="loadingLine"/>
      </v-col>
      <v-col cols="12" md="6">
        <SaleCompareYear :chart-data="chartDataLine2" :loading="loadingLine"/>
      </v-col>
      <v-col cols="12">
        <SaleCompareRanking :chart-data="chartDataLine3" :label3="label3" v-if="!loadingLine" :loading="loadingLine"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {ref, onMounted} from "@vue/composition-api";
import SaleDaily from "./SaleDaily";
import MonthDaily from "./MonthDaily.vue";
import YearDaily from "./YearDaily.vue";
import SaleCompare from "./SaleCompare.vue";
import SaleCompareYear from "./SaleCompareYear.vue";
import SaleCompareRanking from "./SaleCompareRanking.vue";
import {api} from "@/services/api"

export default {
  props: {},
  components: {SaleDaily, MonthDaily, YearDaily, SaleCompare, SaleCompareYear, SaleCompareRanking},
  setup(_, {root, emit}) {
    const countDay = ref('1')
    const loadingLine = ref(false)
    const saleDaily = ref({
      statTitle: '',
      statistics: '0.00',
      avatar: require('@/assets/images/3d-characters/day.png'),
      avatarWidth: '80',
    })
    const saleMonthly = ref({
      statTitle: '',
      statistics: '0.00',
      avatar: require('@/assets/images/3d-characters/month.png'),
      avatarWidth: '80',
    })
    const saleYearly = ref({
      statTitle: '',
      statistics: '0.00',
      avatar: require('@/assets/images/3d-characters/year.png'),
      avatarWidth: '80',
    })

    const shopList = ref([])
    const shopSelection = ref('')

    const chartDataLine = ref([
      {name: '', data: [], type: 'column'},
      {name: '', data: [], type: 'line'},
    ])
    const chartDataLine2 = ref([
      {name: '', data: [], type: 'column'},
      {name: '', data: [], type: 'line'},
    ])
    const chartDataLine3 = ref([
      {name: '', data: [], type: 'column'},
      {name: '', data: [], type: 'line'},
    ])
    const label3 = ref([])

    onMounted(() => {
      getShop()
    })

    const getShop = () => {
      api.get({
        path: 'admin/shops',
      }).then(({data}) => {
        shopList.value = data
        // shopList.value.unshift({
        //   shop_id: '',
        //   shop_name: 'ทั้งหมด',
        // })
        shopSelection.value = shopList.value[0].shop_id
        getDashboard()

      }).catch(err => {
        console.log('error : ', err)
      })

    }

    const getDashboard = () => {
      loadingLine.value = true
      api
          .get({
            path: '/admin/dashboard',
            param: `?shop_id=${shopSelection.value}&currency_id=${localStorage.getItem('currencyId')}`,
          })
          .then(res => {
            saleDaily.value.statistics = res.data.daily
            saleMonthly.value.statistics = res.data.monthly
            saleYearly.value.statistics = res.data.yearly
            chartDataLine.value[0].data = res.data.monthDataByDay
            chartDataLine2.value[0].data = res.data.yearDataByMonth.currentYear
            chartDataLine2.value[1].data = res.data.yearDataByMonth.oldYear
            chartDataLine2.value[0].name = res.data.yearList[0]
            chartDataLine2.value[1].name = res.data.yearList[1]
            chartDataLine3.value[0].data = res.data.rakingProduct.value
            label3.value = res.data.rakingProduct.name
            loadingLine.value = false
          })
          .catch(err => {
            console.log('err', err)
            loadingLine.value = false
          })
    }

    return {
      countDay,
      saleDaily,
      shopList,
      shopSelection,
      saleMonthly,
      saleYearly,
      getDashboard,
      loadingLine,
      label3,
      chartDataLine,
      chartDataLine2,
      chartDataLine3,
    }
  },

}
</script>

<style scoped>

</style>
